import { IS_PROD } from "@Platon/const"
import { cookie } from "@Platon/core/functions/cookies"

export function getToken(dataKey) {
	if (IS_PROD) {
		return localStorage.getItem(dataKey) || cookie.get(dataKey)
	} else {
		return cookie.get(dataKey)
	}
}

export function removeCookie(dataKey) {
	if (IS_PROD) {
		localStorage.removeItem(dataKey)
	} else {
		cookie.remove(dataKey)
	}
}
