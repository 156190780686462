<template>
    <BaseControl
        v-bind="$attrs"
        :errors="combinedErrors"
        :appendElements="(item || {}).addonElementsRight"
        :prependElements="(item || {}).addonElementsLeft"
    >
        <select ref="select" class="form-control" :disabled="isDisabled" :readonly="readonly">
            <!--			<option disabled value="0">Танланг</option>-->
        </select>

        <!--		<b-form-select :value="local" @input="updateLocal" value-field="id" text-field="name" :options="localOptions"/>-->
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"

import $ from "jquery"

import "select2/dist/js/select2.min"
import FormDataMixin from "@Platon/components/form/FormDataMixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import { isEmpty, isNull } from "@Platon/core/helpers"
import { IS_DEV } from "@Platon/const"
import { localeInfo } from "@Platon/core/translations"

export default {
    name: "SelectControl",
    components: { BaseControl },

    mixins: [InputControlMixin, FormDataMixin, ValidationMixin],

    props: {
        value: {}
    },

    data() {
        return {
            relatedFieldValue: "",
            data: this.item.data
        }
    },

    computed: {
        translatedOption() {
            return (this.data || []).map((el) => {
                return {
                    ...el,
                    id: el.id,
                    name: el["name_" + localeInfo.locale] || el.name
                }
            })
        },

        localOptions() {
            let options = this.translatedOption

            if (this.isRelatedSelect && !isEmpty(this.relatedFieldValue) && !isEmpty(this.filterBy)) {
                options = options.filter((o) => {
                    let valid = o[this.filterBy] === this.relatedFieldValue

                    if (!valid && Number.isInteger(o[this.filterBy])) {
                        try {
                            valid = Number(o[this.filterBy]) === Number(this.relatedFieldValue)
                        } catch {}
                    }

                    return valid
                })
            }

            return options
        },

        selectPlaceholder() {
            return this.localOptions.find((x) => x.id === null)
        },

        isRelatedSelect() {
            return this.item.isRelatedSelect
        },

        relatedSelect() {
            return this.item.relatedSelect
        },

        filterBy() {
            return this.item.filterBy
        },

        isDisabled() {
            if (this.isDisabledOrReadOnly) {
                return true
            }

            if (this.isRelatedSelect) return !this.relatedFieldValue

            return false
        },

        isRequired() {
            return this.$attrs.required
        },

        isMultiselect() {
            if (this.item.type === "multiselect") return true

            return this.item.isMultiSelect || false
        },

        allowClear() {
            if (this.$parent.$options.name === "PlatonFormElement" && this.isRequired) return false

            if (typeof this.item.allowClear === "boolean") {
                return this.item.allowClear
            }

            if (this.$parent.$options.name === "PlatonFormElement" && !this.isRequired) return true

            return false
        },

        minimumOptionsToShowSearch() {
            return this.item.minItemsToSearch || 11
        },

        selectedOption() {
            // should be ==
            return this.localOptions.find((option) => option.id == this.local)
        },

        select2ExtraOptions() {
            return {
                templateSelection: this.item.templateSelection,
                templateResult: this.item.templateResult,
                maximumSelectionLength: this.item.selectionCount,
                multiple: this.isMultiselect,
                allowClear: this.allowClear,
                disabled: this.readonly ? "readonly" : undefined,
                placeholder: this.selectPlaceholder || {
                    id: "",
                    text: this.placeholder
                },
                minimumResultsForSearch: this.minimumOptionsToShowSearch
            }
        }
    },
    methods: {
        initSelect2() {
            let vm = this
            let closeTime = 0
            if (IS_DEV) console.log("Rendered")

            let selectValue = this.value

            $(this.$refs.select)
                // init select2
                .off()
                .empty()
                .select2({
                    ...this.select2ExtraOptions,
                    data: this.localOptions,
                    matcher: (params, data) => {
                        data.text = isNull(data.name) ? "" : data.name

                        return $.fn.select2.defaults.defaults.matcher(params, data)
                    },
                    templateSelection: (option) => {
                        return option.name
                    },
                    language: {
                        noResults: () => {
                            return this.$l("platon.select_has_no_data", "Натижа топилмади")
                        },
                        maximumSelected: (limit) => {
                            return this.$ll(
                                "platon.selected_more_items",
                                [limit.maximum],
                                "{0} тадан кўп элемент танлаш мумкин эмас"
                            )
                        }
                    }
                })
                .on("select2:select", function (evt) {
                    var element = evt.params.data.element
                    var $element = $(element)
                    $element.detach()
                    $(this).append($element)
                    $(this).trigger("change")
                })
                .val(selectValue)
                .trigger("change")
                .on("change", () => {
                    /**
                     * @type {array|string}
                     */
                    let val = $(vm.$refs.select).val()

                    // if multi select
                    if (Array.isArray(val) && Array.isArray(this.local)) {
                        // if array length is not equal, update value
                        if (val.length !== this.local.length) {
                            this.updateLocal(val)
                        }
                        // if length is equal, but items not them same, update value
                        else if (val.some((item, index) => this.local[index] !== item)) {
                            this.updateLocal(val)
                        }
                    }
                    // if single select, just check value for equality
                    else if (this.local !== val) this.updateLocal(val)
                })
                .on("select2:closing", () => {
                    closeTime = new Date().getTime()
                })

            $(this.$refs.select)
                .parent()
                .find(".select2-selection--single")
                .keyup((ev) => {
                    if (ev.keyCode === 27 && !ev.defaultPrevented) {
                        if (!(new Date().getTime() - 200 > closeTime)) ev.stopPropagation()
                    }
                })

            $(this.$refs.select)
                .parent()
                .find(".select2-selection")
                .keydown((ev) => {
                    if (ev.which < 32) return

                    let target = jQuery(ev.target).closest(".select2-container")

                    if (!target.length) return

                    target = target.prev()
                    target.select2("open")

                    const search = target.data("select2").dropdown.$search || target.data("select2").selection.$search

                    search.focus()
                })
        }
    },

    mounted: function () {
        this.initSelect2()

        this.$watch("value", (val) => {
            $(this.$refs.select).val(val).trigger("change")
        })
    },

    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler(val, old) {
                if (this.isRelatedSelect) {
                    if (val[this.relatedSelect]) {
                        this.relatedFieldValue = val[this.relatedSelect]
                    } else {
                        this.relatedFieldValue = null
                        this.updateLocal(null)
                    }
                }
            }
        },

        item: {
            deep: true,
            handler(val) {
                this.data = val.data
            }
        },

        localOptions: function (options) {
            this.initSelect2()

            if (
                !(this.value === null || this.value === undefined) &&
                options.findIndex((c) => c.id == this.value) === -1
            ) {
                this.updateLocal(null)
            }
        },

        "item.selectionCount"() {
            this.initSelect2()
        }
    },

    beforeDestroy: function () {
        $(this.$refs.select).select2("close")

        $(this.$refs.select).off().select2("destroy")
    }
}
</script>
