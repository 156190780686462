function matchesPattern(pattern, path) {
	if (pattern.endsWith("/*")) {
		pattern = pattern.slice(0, -2)
	}

	return path.startsWith(pattern)
}

function call24Toggle(state) {
	const c24 = window.Call24.API
	if (state && c24.isDestroyed()) {
		c24.init()
	}

	if (!state && c24.isInitialized()) {
		c24.destroy()
	}
}

function checkPagesPath(call24, type, path) {
	if (call24[type].all) {
		call24Toggle(true)
	} else {
		let pageConf =
			call24[type].pages &&
			call24[type].pages.find((c) => {
				if (c.path == path) {
					return true
				} else if (matchesPattern(c.path, path)) {
					return true
				} else {
					return false
				}
			})
		if (pageConf && pageConf.state) {
			call24Toggle(true)
		} else {
			call24Toggle(false)
		}
	}
}

export const call24Matcher = (info = {}, to) => {
	let call24 = info.call24
	let { path } = to

	if (call24 && call24.hasOwnProperty("main") && window.Call24) {
		if (call24.main && path == "/") {
			call24Toggle(true)
		} else if (path.match(/public/)) {
			checkPagesPath(call24, "public", path)
		} else {
			checkPagesPath(call24, "private", path)
		}
	}
}
