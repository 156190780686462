<script>
import { cloneDeep } from "lodash"
import { TableBuilder } from "@Platon/components/table/TableBuilder"
import TableNoData from "@Platon/components/table/TableNoData.vue"
import TableHeaderCell from "@Platon/components/table/TableHeaderCell.vue"
import TableCell from "@Platon/components/table/TableCell.vue"
import TableFooterMixin from "@Platon/mixins/table/TableFooterMixin"
import { getElementInPathByTag, getTextWidth } from "@Platon/core/helpers"
import { Keys } from "@Platon/const"
import groupBy from "lodash.groupby"
import ExportTableMixin from "@Platon/mixins/ExportTableMixin"
import TableFilterColumnsWrapper from "@Platon/components/table/filters/TableFilterColumnsWrapper.vue"

/**
 * @typedef TableSort
 * @property {string} column
 * @property {1|-1} direction
 */

/**
 * @param {number} index
 * @param {Object} dataRow
 * @param {Object} table
 * @return {{mouseover(*): void, mouseout(*): void}}
 */
const rowHoverEvents = (index, dataRow, table) => {
    return {
        mouseover(event) {
            let el = getElementInPathByTag(eventPath(event), "table")

            if (el && el.parentElement) {
                let tr = el.parentElement.querySelectorAll(`table > tbody > tr:nth-child(${index})`)

                for (let row of tr) {
                    row.classList.add("hover")
                }
            }
            table.eventEmitter.$emit("onRowHover", index - 1, dataRow)
        },

        mouseout(event) {
            let el = getElementInPathByTag(eventPath(event), "table")

            if (el && el.parentElement) {
                let tr = el.parentElement.querySelectorAll(`table > tbody > tr:nth-child(${index})`)

                for (let row of tr) {
                    row.classList.remove("hover")
                }
            }
        },

        click(event) {
            if (table.navigateByKeyboard && Number.isInteger(dataRow._rownum)) {
                table.focusedRow = index
                table.eventEmitter.$emit("onRowClick", index - 1, dataRow)
            }
        },

        dblclick(event) {
            if (table.navigateByKeyboard && Number.isInteger(dataRow._rownum)) {
                table.eventEmitter.$emit("onRowDblClick", index - 1, dataRow)
            }
        }
    }
}

/**
 * @param {Object[]} data
 * @param {string} key
 *
 * @returns {string[]}
 */
function pickOrdered(data, key) {
    const allKeys = data.reduce((set, item) => {
        if (key in item) {
            set.add(item[key])
        }

        return set
    }, new Set())

    return Array.from(allKeys)
}

function renderFooterCell($c, index, dataRow, columnDef, td) {
    return $c(TableCell, {
        props: {
            column: td,
            record: dataRow,
            text: dataRow[td.dataField],
            td: columnDef
        },

        attrs: {
            clone: true,
            "data-cell-type": "footer",
            rowIndex: index,
            width: td.width,
            nowrap: td.footerNowrap
        }
    })
}

export default {
    name: "PTable",

    mixins: [TableFooterMixin, ExportTableMixin],

    props: {
        /** @type TableColumn[] */
        columns: {},

        extraColumns: {
            type: Array,
            default: () => []
        },

        /** @type Object[] */
        tableData: {
            type: Array,
            default: () => []
        },

        tableName: {
            type: String,
            required: false
        },

        tableId: Number,

        leftFixed: {
            default: 0,
            type: Number
        },

        rightFixed: {
            default: 0,
            type: Number
        },

        navigateByKeyboard: {
            default: false,
            type: Boolean
        },

        navigateSelectRow: {
            default: false,
            type: Boolean
        },

        fixedTable: {
            type: Boolean,
            default: false
        },

        groupBy: String,

        tableClass: {
            type: String,
            default: ""
        },

        tableStyle: {
            type: String,
            default: ""
        },

        maxHeight: {
            type: String,
            default: undefined
        },

        splitRowsBy: {
            type: Array,
            default: () => []
        },

        level: {
            default: 1,
            type: Number
        },

        columnsConfig: {
            type: Object,
            default: () => {}
        }
    },

    inject: {
        platonTable: {
            default: null
        }
    },

    provide() {
        return {
            ptable: this
        }
    },

    data() {
        let isPlatonTable = this.platonTable !== null
        let eventEmitter = isPlatonTable ? this.platonTable : this

        return {
            isFixedColumnsReady: true,
            disabledColumns: {
                all: true,
                columns: this.columnsConfig?.hidden || []
            },
            orders: this.columnsConfig?.order || {},
            titles: this.columnsConfig?.titles || {},
            focusedRow: -1,
            focused: false,

            isPlatonTable,
            eventEmitter,
            groupSize: 0,
            isMobile: window.innerWidth < 768,

            /** @type {TableSort|undefined} */
            sortBy: undefined
        }
    },

    methods: {
        collectDisabledColumns(data, list = null) {
            return data.reduce((acc, curr) => {
                if (!curr.enabled) {
                    acc.push(curr.id)
                }

                if (curr.children.length > 0) {
                    acc = this.collectDisabledColumns(curr.children, acc)
                }

                return acc
            }, list ?? [])
        },
        transformTitles(columns) {
            return columns.map((column) => {
                if (column.id in this.titles) {
                    column.title = this.titles[column.id]
                }

                if (column.children.length > 0) {
                    column.children = this.transformTitles(column.children)
                }

                return column
            })
        },
        filterColumn(columns) {
            const hiddenColumns = this.disabledColumns.columns

            return columns.reduce((acc, curr) => {
                if (!hiddenColumns.includes(curr.id)) {
                    let clone = { ...curr }
                    acc.push(clone)

                    if (clone.children.length > 0) {
                        clone.children = this.filterColumn([...clone.children])
                    }
                }

                return acc
            }, [])
        },
        notDraggableColumns() {
            let arr = []
            let columns = this.columns
            if (this.leftFixed > 0) {
                arr.push(...columns.slice(0, this.leftFixed))
            }

            if (this.rightFixed > 0) {
                arr.push(...columns.slice(-this.rightFixed))
            }

            return arr.map((el) => el.id)
        },
        showColumnFilter() {
            const columnInitialOrders = cloneDeep(this.orders)
            if (this.tableName === undefined) throw new Error("Table name is required for column filter")

            const setInitialFilters = (columns, disabled) => {
                const notDraggableColumns = this.notDraggableColumns()
                const transformedColumns = columns.map((column) => {
                    const isEnabled = !this.disabledColumns.columns.includes(column.id)
                    const isChildDisabled = disabled !== undefined ? disabled : !isEnabled
                    const children = Array.isArray(column.children)
                        ? setInitialFilters(column.children, isChildDisabled)
                        : []

                    return {
                        id: column.id,
                        disabled: disabled || false,
                        title: column.title,
                        enabled: isEnabled,
                        children,
                        isDraggable: !notDraggableColumns.includes(column.id)
                    }
                })

                return transformedColumns
            }

            const resolveModal = (res) => {
                let disabledResColumns = { all: res.all, columns: this.collectDisabledColumns(res.columns) }

                this.sendColumnsFilter(disabledResColumns.columns, res.orders, res.titles).then(() => {
                    this.orders = res.orders
                    this.titles = res.titles
                    this.disabledColumns = disabledResColumns
                    this.isFixedColumnsReady = !this.isFixedColumnsReady
                    this.$nextTick(() => (this.isFixedColumnsReady = true))
                })
            }

            const rejectModal = () => {
                this.orders = columnInitialOrders
            }

            this.$modal.show(
                TableFilterColumnsWrapper,
                {
                    changeMainOrders: (orders = {}) => (this.orders = { ...orders }),
                    orders: cloneDeep(this.orders),
                    disabledColumns: this.disabledColumns,
                    columns: setInitialFilters(this.transformTitles(cloneDeep(this.sortedColumns))),
                    originalColumns: setInitialFilters(this.columns),
                    columnInitialTitles: this.getColumnTitles(cloneDeep(this.columns)),
                    titles: cloneDeep(this.titles),
                    resolveModal,
                    rejectModal
                },
                { width: "500px", draggable: ".modal-form-header", height: "80%", clickToClose: false }
            )
        },

        /**
         * @param {Object} columns
         */
        async sendColumnsFilter(columns, order, titles) {
            try {
                await this.$http.post("/user/column", {
                    level: this.level,
                    tableId: this.tableId,
                    column_config: {
                        order,
                        titles,
                        hidden: columns
                    }
                })
            } catch (e) {
                console.warn(e)
            }
        },

        getColumnTitles(columns) {
            return columns.reduce((acc, curr) => {
                acc[curr.id] = curr.title
                if (curr.children.length > 0) {
                    Object.assign(acc, this.getColumnTitles(curr.children))
                }

                return acc
            }, {})
        },

        /**
         * @param {number} row - Index of row, starts from 1
         */
        navigateToRow(row) {
            if (!this.focused) {
                let table = this.$el.querySelector("table.platon-table")

                this.focused = true
                table.focus()
            }

            if (this.focusedRow !== row) {
                this.focusedRow = row
            }
        },

        handleNavigationByKeyboard() {
            let table = this.$el.querySelector("table.platon-table")

            table.addEventListener("blur", () => {
                this.focused = false
                this.focusedRow = -1
            })

            table.addEventListener("keydown", (event) => {
                if (event.keyCode === Keys.ArrowUp) {
                    this.focusedRow = this.getNextFocusableRow(this.focusedRow, -1)
                    if (this.navigateSelectRow) this.onCurrentRowSelected()

                    event.preventDefault()
                } else if (event.keyCode === Keys.ArrowDown) {
                    this.focusedRow = this.getNextFocusableRow(this.focusedRow, 1)
                    if (this.navigateSelectRow) this.onCurrentRowSelected()

                    event.preventDefault()
                } else if (event.keyCode === Keys.Enter) {
                    this.onCurrentRowSelected()
                    event.preventDefault()
                } else if (event.keyCode === Keys.Esc) {
                    event.preventDefault()
                    table.blur()
                }
            })
        },

        handleFixedHeight() {
            let isSmallHeight = window.screen.height < 800
            let table = this.$el.querySelector(".table-responsive")

            if (!table) return

            table.removeEventListener("scroll", this.tableFixHead)
            this.$nextTick(() => this.tableFixHead({ target: this.$el }))

            let toolbarsHeights = 0

            if (this.isPlatonTable) {
                const filtersHeight = Array.from(this.platonTable.$el.querySelectorAll(".table-filters"))
                    .map((x) => x.clientHeight)
                    .reduce((total, cur) => total + cur, 0)

                const controlButtons = this.platonTable.$el.querySelector(".control-buttons")

                toolbarsHeights = [
                    this.platonTable.$refs["header"] ? this.platonTable.$refs["header"].clientHeight : 0,
                    this.platonTable.$refs["pagination"] ? this.platonTable.$refs["pagination"].$el.clientHeight : 0,
                    controlButtons ? controlButtons.clientHeight : 0,
                    filtersHeight,
                    95 // extra padding, margins
                ].reduce((a, b) => a + (b || 0), 0)
            }

            let calculatedHeight = `calc(100vh - ${isSmallHeight ? 0 : toolbarsHeights}px)`
            table.classList.add("table-h-scroll")
            table.style.maxHeight = this.maxHeight || calculatedHeight

            table.addEventListener("scroll", this.tableFixHead)
        },

        /**
         * @param {Event} e
         */
        tableFixHead(e) {
            /** @type HTMLElement */
            const el = e.target

            const scrollTop = el.scrollTop,
                tHeight = el.querySelector("table").clientHeight,
                footerOffset = tHeight - el.clientHeight

            const scrollLeft = el.scrollLeft
            const footerTranslate = Math.max(footerOffset - scrollTop, 0)

            /** @type HTMLElement */
            let leftFixedTable = el.querySelector(".platon-table-fix-left")
            if (leftFixedTable) {
                leftFixedTable.style.transform = `translateX(${scrollLeft}px)`
            }

            /** @type HTMLElement */
            let rightFixedTable = el.querySelector(".platon-table-fix-right")
            if (rightFixedTable) {
                rightFixedTable.style.transform = `translateX(${scrollLeft}px)`
            }

            el.querySelectorAll("thead tr").forEach((th) => (th.style.transform = `translateY(${scrollTop}px)`))
            el.querySelectorAll("tfoot tr").forEach((th) => (th.style.transform = `translateY(-${footerTranslate}px)`))

            // stick table grouping texts

            // if element hidden we can't calculate it's width, therefore we get width of parents'
            let maxAvailableWidth = this.getMaxAvailableWidthRecursive(el.parentElement)
            let fixedPadding = Math.min(maxAvailableWidth / 2) + scrollLeft
            let topSticky = el.querySelector("thead").getBoundingClientRect().height + "px"

            el.querySelectorAll(".platon-table td.group-cell").forEach((th) => {
                let cellTextWidth = getTextWidth(th.textContent)

                th.style.paddingLeft = fixedPadding - cellTextWidth / 2 + "px"
                th.style.top = topSticky
            })
        },
        //end

        /**
         * @param {HTMLElement} el
         */
        getMaxAvailableWidthRecursive(el) {
            while (el) {
                let width = el.getBoundingClientRect().width

                if (width > 0) {
                    return width
                }

                el = el.parentElement
            }

            return 0
        },

        /**
         * @param {number} fromIndex
         * @param {'1'|'-1'|number} direction
         *
         * @returns {number}
         */
        getNextFocusableRow(fromIndex, direction) {
            let lookingFor = fromIndex + direction

            while (lookingFor >= 1 && lookingFor <= this.normalizeData.length) {
                if (Number.isSafeInteger(this.normalizeData[lookingFor - 1]._rownum)) {
                    return lookingFor
                }

                lookingFor += direction
            }

            return fromIndex
        },

        onCurrentRowSelected() {
            this.eventEmitter.$emit("rowSelected", this.focusedRow, this.normalizeData[this.focusedRow - 1])
        },

        /**
         * @param {TableColumn[]} nodes
         */
        getSumOfBottomCells(nodes) {
            const sum = (node) => {
                if (Array.isArray(node.children) && node.children.length > 0) {
                    return this.getSumOfBottomCells(node.children)
                } else {
                    return 1
                }
            }

            return nodes.reduce((t, c) => t + sum(c), 0)
        },

        exportXlsx() {
            this.exportTableXlsx(this.$el.querySelector("table.platon-table"))
        },

        sortColumn(dataField) {
            if (this.sortBy && this.sortBy.column === dataField) {
                this.sortBy = {
                    column: dataField,
                    direction: this.sortBy.direction === 1 ? -1 : this.sortBy.direction === -1 ? undefined : 1
                }
            } else {
                this.sortBy = {
                    column: dataField,
                    direction: 1
                }
            }
        }
    },

    watch: {
        focusedRow(row) {
            if (row >= 0) {
                this.navigateToRow(row)
            }
        }
    },

    mounted() {
        if (this.navigateByKeyboard) {
            this.$nextTick(this.handleNavigationByKeyboard)
        }

        this.$nextTick(this.handleFixedHeight)
    },

    beforeDestroy() {},

    activated() {
        this.$nextTick(this.handleFixedHeight)
    },

    render($c, context) {
        let children = []

        let tableStyles = [this.fixedTable ? "table-layout: fixed" : undefined, this.tableStyle].filter((x) => !!x)

        let tableOptions = {
            attrs: {
                class: "platon-table table table-bordered",
                tabIndex: this.navigateByKeyboard ? 0 : undefined,
                style: tableStyles.join(";")
            },

            on: {}
        }

        let focusClass = this.focused ? " focus " : ""

        children.push($c("table", tableOptions, this.rowElements))

        if (this.leftFixed > 0 && this.isFixedColumnsReady && !this.isMobile) {
            children.push(
                $c(
                    "table",
                    {
                        attrs: {
                            class: "platon-table-fix-left table table-bordered" + focusClass,
                            style: "width: auto"
                        }
                    },
                    this.leftElements
                )
            )
        }

        if (this.rightFixed > 0 && this.isFixedColumnsReady && !this.isMobile) {
            children.push(
                $c(
                    "table",
                    {
                        attrs: {
                            class: "platon-table-fix-right table table-bordered" + focusClass,
                            style: "width: auto"
                        }
                    },
                    this.rightElements
                )
            )
        }
        return $c(
            "div",
            {
                attrs: {
                    class: this.tableClass + " position-relative table-wrapper " + focusClass
                }
            },
            [$c("div", { attrs: { class: "table-responsive mb-0" } }, children), !this.hasData ? $c(TableNoData) : []]
        )
    },

    computed: {
        localColumns() {
            return this.transformTitles(this.filterColumn(this.sortedColumns))
        },

        sortedColumns() {
            const orders = this.orders
            const columnSorter = (children) => {
                let childCurrentIndex = 0
                const columns = Array.isArray(children) && children.length > 0 ? children : this.columns

                if (!orders || Object.keys(orders).length === 0) return columns

                return columns.reduce((sortedColumns, column) => {
                    let columnNewIndex = orders[column.id]
                    if (column.id in orders) {
                        sortedColumns.splice(columnNewIndex, 1, { ...column })
                        if (column.children.length > 0) {
                            sortedColumns[columnNewIndex].children = columnSorter(column.children)
                        }
                    } else {
                        sortedColumns.splice(childCurrentIndex, 1, { ...column })
                        childCurrentIndex++
                    }

                    return sortedColumns
                }, new Array(columns.length).fill())
            }

            return columnSorter()
        },
        /**
         * @return {TableColumn[]}
         */
        bottomColumns() {
            /**
             * @param {TableColumn[]} cols
             * @return {TableColumn[]}
             */
            const findBottom = (cols) => {
                return cols.reduce((arr, item) => {
                    if (Array.isArray(item.children) && item.children.length > 0) {
                        arr.push(...findBottom(item.children))
                    } else {
                        arr.push(item)
                    }

                    return arr
                }, [])
            }

            return findBottom(this.localColumns)
        },

        hasData() {
            return this.items.length > 0
        },

        formattedColumns() {
            let columns = [...this.localColumns]

            const formatColumn = (/** TableColumn[] */ arr) => {
                for (let col of arr) {
                    col.__isComplexCell =
                        (typeof col.cellFormat === "string" && col.cellFormat.trim().length > 0) ||
                        (col.cellFormat !== null && typeof col.cellFormat === "object") ||
                        typeof col.cellFormat === "function"

                    col.__hasLink = typeof col.link === "string" && col.link.length > 0
                    col.__hasHint = typeof col.hint === "string" && col.hint.length > 0

                    if (this.sortBy) {
                        col.__sortDirection = col.dataField === this.sortBy.column ? this.sortBy.direction : undefined
                    }

                    if (Array.isArray(col.children) && col.children.length > 0) {
                        formatColumn(col.children)
                    } else {
                        col.__bottomColumn = true
                    }
                }
            }

            formatColumn(columns)

            columns.push(...this.extraColumns)

            return columns
        },

        /**
         * @return {Cell[][]}
         */
        columnDefinition() {
            return new TableBuilder(this.formattedColumns).getAutoColumn()
        },

        leftFixedColDefinition() {
            if (this.leftFixed > 0) {
                let fixedCols = this.formattedColumns.slice(0, this.leftFixed).filter((f) => {
                    return this.columns.some((lf, li) => lf.dataField === f.dataField && li < this.leftFixed)
                })

                return new TableBuilder(fixedCols).getAutoColumn()
            } else return []
        },

        rightFixedColDefinition() {
            if (this.rightFixed > 0) {
                let minIndex = this.columns.length - this.rightFixed
                let fixedCols = this.formattedColumns.slice(-this.rightFixed).filter((f) => {
                    return this.columns.some((lf, li) => lf.dataField === f.dataField && li >= minIndex)
                })

                return new TableBuilder(fixedCols).getAutoColumn()
            } else return []
        },

        /**
         * @return {Cell[]}
         */
        dataFields() {
            let dataFields = []

            this.columnDefinition.forEach((row) => {
                dataFields.push(...row.filter((value) => value.isDataField))
            })

            return dataFields
        },

        dataFieldsWithKeys() {
            return this.dataFields.reduce((o, c) => {
                o[c.column.id] = c

                return o
            }, {})
        },

        /**
         * @return {TableColumn[]}
         */
        dataFieldColumns() {
            return this.dataFields.map((x) => x.column)
        },

        /**
         * @return {TableColumn[]}
         */
        leftDataFieldColumns() {
            const size =
                Array.isArray(this.leftFixedColDefinition) &&
                Array.isArray(this.leftFixedColDefinition[0]) &&
                this.leftFixedColDefinition[0].length

            let leftBottomColumnAmount = this.getSumOfBottomCells(this.localColumns.slice(0, size))

            return this.bottomColumns.slice(0, leftBottomColumnAmount)
        },

        /**
         * @return {TableColumn[]}
         */
        rightDataFieldColumns() {
            const size =
                Array.isArray(this.rightFixedColDefinition) &&
                Array.isArray(this.rightFixedColDefinition[0]) &&
                this.rightFixedColDefinition[0].length

            if (size) {
                let rightBottomColumnAmount = this.getSumOfBottomCells(
                    this.localColumns.slice(this.localColumns.length - size)
                )

                return this.bottomColumns.slice(-rightBottomColumnAmount)
            } else return []
        },

        renderHeaderElements() {
            let $c = this.$createElement

            return $c(
                "thead",
                this.columnDefinition.map((tr) => {
                    return $c(
                        "tr",
                        tr.map((td) => {
                            return $c(TableHeaderCell, {
                                props: {
                                    column: td.column,
                                    td: td
                                },
                                on: {
                                    sort: () => {
                                        this.sortColumn(td.column.dataField)
                                    }
                                },
                                attrs: {
                                    "data-col-id": `column-${td.column.id}`,
                                    width: td.column.width,
                                    nowrap: td.column.dataNowrap,
                                    colspan: td.colspan,
                                    rowspan: td.rowspan
                                }
                            })
                        })
                    )
                })
            )
        },

        renderFooterElements() {
            let $c = this.$createElement

            return $c(
                "tfoot",
                this.footerData.map((dataRow, index) => {
                    return $c(
                        "tr",
                        { class: "tfoot" },
                        this.bottomColumns.map((td) => {
                            let columnDef = this.dataFieldsWithKeys[td.id]

                            return $c(TableCell, {
                                props: {
                                    column: td,
                                    record: dataRow,
                                    text: dataRow[td.dataField],
                                    td: columnDef
                                },

                                attrs: {
                                    "data-cell-type": "footer",
                                    "data-cell-id": `${td.id}-${index}`,
                                    rowIndex: index,
                                    width: td.width,
                                    nowrap: td.footerNowrap
                                }
                            })
                        })
                    )
                })
            )
        },

        renderDataElements() {
            let $c = this.$createElement

            return $c(
                "tbody",
                this.normalizeData.map((dataRow, index) => {
                    if (dataRow.__rowType === "header") {
                        return $c("tr", [
                            $c(TableCell, {
                                props: {
                                    column: this.dataFieldsWithKeys[dataRow.id]
                                        ? this.dataFieldsWithKeys[dataRow.id].column
                                        : {},
                                    record: dataRow,
                                    text: dataRow.text
                                },

                                attrs: {
                                    colspan: dataRow.__colspan
                                }
                            })
                        ])
                    }

                    return $c(
                        "tr",
                        {
                            on: {
                                ...rowHoverEvents(index + 1, dataRow, this)
                            },
                            class: {
                                focus: this.focusedRow === index + 1,
                                tfoot: dataRow.__rowType === "footer"
                            }
                        },
                        this.bottomColumns.map((td) => {
                            let rowspan = 1

                            if (dataRow.__splited === true) {
                                if (
                                    !Number.isInteger(dataRow.__splitedSize) &&
                                    !this.splitRowsBy.includes(td.dataField)
                                ) {
                                    return undefined
                                } else if (!this.splitRowsBy.includes(td.dataField)) {
                                    rowspan = dataRow.__splitedSize
                                }
                            }

                            let columnDef = this.dataFieldsWithKeys[td.id]

                            return $c(TableCell, {
                                props: {
                                    column: td,
                                    record: dataRow,
                                    text: dataRow[td.dataField],
                                    td: columnDef
                                },

                                attrs: {
                                    "data-cell-type": "cell",
                                    "data-cell-id": `${td.id}-${index}`,
                                    rowIndex: index,
                                    width: td.width,
                                    rowspan: rowspan,
                                    nowrap: dataRow.__rowType === "footer" ? td.footerNowrap : td.dataNowrap
                                }
                            })
                        })
                    )
                })
            )
        },

        /**
         * @return {VNode[]}
         */
        rowElements() {
            let rows = []

            rows.push(this.renderHeaderElements)
            rows.push(this.renderDataElements)
            rows.push(this.renderFooterElements)

            return rows
        },

        leftElements() {
            let rows = []

            let $c = this.$createElement

            let head = $c(
                "thead",
                this.leftFixedColDefinition.map((tr) => {
                    return $c(
                        "tr",
                        tr.map((td) => {
                            return $c(TableHeaderCell, {
                                props: {
                                    column: td.column,
                                    td: td
                                },
                                on: {
                                    sort: () => {
                                        this.sortColumn(td.column.dataField)
                                    }
                                },
                                attrs: {
                                    clone: true,
                                    "data-cell-type": "cell",
                                    width: td.column.width,
                                    nowrap: td.column.dataNowrap,
                                    colspan: td.colspan,
                                    rowspan: td.rowspan
                                }
                            })
                        })
                    )
                })
            )

            let body = $c(
                "tbody",
                this.normalizeData.map((dataRow, index) => {
                    return $c(
                        "tr",
                        {
                            on: { ...rowHoverEvents(index + 1, dataRow, this) },
                            class: { focus: this.focusedRow === index + 1 }
                        },
                        this.leftDataFieldColumns.map((td) => {
                            let columnDef = this.dataFieldsWithKeys[td.id]

                            return $c(TableCell, {
                                props: {
                                    column: td,
                                    record: dataRow,
                                    text: dataRow.__rowType === "header" ? "&nbsp;" : dataRow[td.dataField],
                                    td: columnDef
                                },

                                attrs: {
                                    rowIndex: index,
                                    clone: true,
                                    "data-cell-type": "cell",
                                    width: td.width,
                                    nowrap: dataRow.__rowType === "footer" ? td.footerNowrap : td.dataNowrap
                                }
                            })
                        })
                    )
                })
            )

            let footer = $c(
                "tfoot",
                this.footerData.map((dataRow, index) => {
                    return $c(
                        "tr",
                        this.leftDataFieldColumns.map((td) => {
                            let columnDef = this.dataFieldsWithKeys[td.id]

                            return renderFooterCell.call(this, $c, index, dataRow, columnDef, td)
                        })
                    )
                })
            )

            rows.push(head)
            rows.push(body)
            rows.push(footer)

            return rows
        },

        rightElements() {
            let rows = []

            let $c = this.$createElement

            let head = $c(
                "thead",
                this.rightFixedColDefinition.map((tr) => {
                    return $c(
                        "tr",
                        tr.map((td) => {
                            return $c(TableHeaderCell, {
                                props: {
                                    column: td.column,
                                    td: td
                                },
                                on: {
                                    sort: () => {
                                        this.sortColumn(td.column.dataField)
                                    }
                                },
                                attrs: {
                                    clone: true,
                                    width: td.column.width,
                                    nowrap: td.column.dataNowrap,
                                    colspan: td.colspan,
                                    rowspan: td.rowspan
                                }
                            })
                        })
                    )
                })
            )

            let body = $c(
                "tbody",
                this.normalizeData.map((dataRow, index) => {
                    return $c(
                        "tr",
                        {
                            on: { ...rowHoverEvents(index + 1, dataRow, this) },
                            class: { focus: this.focusedRow === index + 1 }
                        },
                        this.rightDataFieldColumns.map((td) => {
                            let columnDef = this.dataFieldsWithKeys[td.id]

                            return $c(TableCell, {
                                props: {
                                    column: td,
                                    record: dataRow,
                                    text: dataRow.__rowType === "header" ? "&nbsp;" : dataRow[td.dataField],
                                    td: columnDef
                                },

                                attrs: {
                                    rowIndex: index,
                                    clone: true,
                                    "data-cell-type": "cell",
                                    width: td.width,
                                    nowrap: dataRow.__rowType === "footer" ? td.footerNowrap : td.dataNowrap
                                }
                            })
                        })
                    )
                })
            )

            let footer = $c(
                "tfoot",
                this.footerData.map((dataRow, index) => {
                    return $c(
                        "tr",
                        this.rightDataFieldColumns.map((td) => {
                            let columnDef = this.dataFieldsWithKeys[td.id]

                            return renderFooterCell.call(this, $c, index, dataRow, columnDef, td)
                        })
                    )
                })
            )

            rows.push(head)
            rows.push(body)
            rows.push(footer)

            return rows
        },

        normalizeData() {
            let data = []

            let headerId = 1
            let footerId = 1
            let { pageSize, currentPage } = this.platonTable

            const assignRownum = (array) => {
                const split = this.splitRowsBy.length > 0
                let index = currentPage * pageSize - (pageSize - 1) || 1

                array.forEach((item) => {
                    if (split) {
                        if (typeof item.__splitedSize === "number") item["_rownum"] = index++
                    } else {
                        item["_rownum"] = index++
                    }
                })
            }

            const generateGroupHeader = (key) => {
                return {
                    __rowType: "header",
                    __rowId: `h${headerId++}`,
                    __colspan: this.bottomColumns.length,
                    text: key,
                    dataField: this.groupBy
                }
            }

            if (this.groupBy && this.groupBy !== "null") {
                // returns {group1: array[], group2: array[], ...}
                /**
                 * @type Object
                 */
                const groupedData = groupBy(this.items, this.groupBy)
                const groupingKeys = pickOrdered(this.items, this.groupBy)

                this.groupSize = groupingKeys.length

                Object.values(groupedData).forEach((/** @type object[] */ arr) => {
                    assignRownum(arr)
                })

                groupingKeys.forEach((key) => {
                    let footer =
                        groupedData[key].length > 1
                            ? this.generateFooterFor(this.bottomColumns, groupedData[key], `f${footerId++}`)
                            : []

                    data.push(
                        generateGroupHeader(key), // HEADER
                        ...groupedData[key], // DATA
                        ...footer // FOOTER
                    )
                })
            } else {
                let tableData = this.items
                assignRownum(tableData)
                data = tableData
            }

            return data
        },

        footerData() {
            let hasGroups = false
            let groups = this.groupSize
            let data = this.items

            if (this.groupBy) {
                hasGroups = true
            }

            if ((data.length > 1 && !hasGroups) || (hasGroups && groups > 1)) {
                return this.generateFooterFor(this.bottomColumns, data, `main_f`)
            } else {
                return []
            }
        },

        items() {
            let data = [...this.tableData]

            if (this.splitRowsBy.length > 0) {
                const splitRows = []

                data.forEach((row) => {
                    const maxRows = this.splitRowsBy.reduce((max, field) => {
                        if (Array.isArray(row[field])) {
                            return Math.max(max, row[field].length)
                        } else {
                            try {
                                row[field] = JSON.parse(row[field])

                                return Math.max(max, row[field].length)
                            } catch (e) {
                                return max
                            }
                        }
                    }, 1)

                    for (let rowI = 0; rowI < maxRows; rowI++) {
                        const newRow = rowI === 0 ? { ...row } : {}
                        newRow.__splited = true

                        if (rowI === 0) {
                            newRow.__splitedSize = maxRows
                        }

                        this.splitRowsBy.forEach((splitData) => {
                            if (Array.isArray(row[splitData])) {
                                newRow[splitData] = row[splitData][rowI]
                            }
                        })

                        splitRows.push(newRow)
                    }
                })

                data = splitRows
            }

            if (this.sortBy && this.sortBy.column) {
                data.sort((a, b) => {
                    const column = this.sortBy.column

                    if (a[column] < b[column]) {
                        return -1 * Math.sign(this.sortBy.direction)
                    } else if (a[column] > b[column]) {
                        return Math.sign(this.sortBy.direction)
                    } else {
                        return 0
                    }
                })
            }

            return data
        }
    }
}
</script>

<style></style>
