import EimzoMixin from "@Platon/components/login/EimzoMixin"

export default {
	mixins: [EimzoMixin],

	data() {
		return {
			error: null,
			client: null,
			hasIdCard: false,
			isIDCardLoading: false
		}
	},

	created() {
		this.client = this.getClient()

		this.initEimzo()
	},

	methods: {
		async initEimzo() {
			if (this.isDirectClient) {
				await this.installProjectApiKey()
			}

			try {
				await this.client.install()
			} catch (e) {
				this.error = e
			}
		},
		async checkHasIdCard() {
			this.hasIdCard = await this.client.isIDCardPlugged()
		},

		async pickIDCard() {
			this.isIDCardLoading = true
			let text = new Date().getTime().toString()
			try {
				return await this.client.createPkcs7("idcard", text, this.getTimestampForSignature)
			} catch (err) {
				throw new Error(err)
			} finally {
				this.isIDCardLoading = false
			}
		}
	}
}
