<template>
    <Container class="ProfileView__gawp">
        <div class="ProfileView__gawp--header">
            <h4 class="header-typography">{{ $l("platon.my-profile", "Менинг профилим") }}</h4>
            <button
                style="visibility: hidden"
                :class="`header-action ${unsavedWarning ? 'not-saved' : 'saved-success'}`"
                @click="toggleEditingOption"
            >
                {{
                    unsavedWarning
                        ? $l("platon.alert-not-saved", "Не сохранено")
                        : $l("platon.change-profile", "Изменить профиль")
                }}
                <i :class="`mdi mdi-${unsavedWarning ? 'alert-rhombus' : 'account-edit'}`" />
            </button>
        </div>
        <div class="ProfileView__gawp--profile account">
            <figure class="account__profileCase">
                <img
                    alt="Account Profile Image"
                    src="../../../assets/default-avatar.jpg"
                    class="account__profileCase--image account-image"
                />
                <figcaption class="account__profileCase--caption account-edit-caption">
                    <h3 class="account-edit-caption-button">
                        <i class="mdi mdi-camera-account" />
                        {{ $l("platon.change-image", "Расмни ўзгартириш") }}
                    </h3>
                </figcaption>
            </figure>
            <div :class="`account__profileDetails ${editDetails ? 'details-editing-on' : 'details-editing-off'}`">
                <h3 class="account__profileDetails--username">@{{ account.username }}</h3>
                <div class="account__profileDetails--fullName">
                    <h3 class="account-fullName">
                        {{ account.fullName }}
                    </h3>
                    <input class="account-fullName-edit" v-model="accountNameModel" />
                </div>
                <!-- <div class="account__profileDetails--thirdPartyConnections">
                    <h3 class="third-party-client">
                        <i class="third-party-client--fb" />
                        @clientUsername
                    </h3>
                </div> -->
            </div>
        </div>
    </Container>
</template>

<script>
import Container from "../components/container"

export default {
    name: "profileDetails",
    components: { Container },
    data() {
        return {
            editDetails: false,
            unsavedWarning: false,
            accountNameModel: null,
            accountUserNameModel: null,
            account: {
                fullName: null,
                username: null,
                photo: null
            }
        }
    },
    methods: {
        toggleEditingOption() {
            this.editDetails = !this.editDetails
        },
        getUserDetails() {
            const { fullName, username, photo } = this.$user
            this.account = {
                ...this.account,
                fullName,
                username,
                photo
            }
        }
    },
    mounted() {
        this.getUserDetails()
        // console.log("user info", this.$user)
    },
    watch: {
        accountNameModel: function () {
            this.unsavedWarning = true
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../profileDetails.scss";
</style>
