<template>
    <button type="button" :disabled="loading || disabled" class="btn align-items-center" :class="classes">
        <b-spinner class="primary-text mr-2" small v-if="loading"></b-spinner>
        <i :class="icon" class="mr-1" v-if="icon" />
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "Btn",

    props: {
        icon: {},
        loading: {
            default: false,
            type: Boolean
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },

    computed: {
        classes() {
            let predefinedClasses = (this.$vnode.data.staticClass || "") + " " + (this.$vnode.data.class || "")
            let classes = new Set()
            let hasButtonStyle = predefinedClasses.match(
                /btn-(outline-)?(primary|warning|danger|success|info|secondary|dark-red)/g
            )

            if (this.$attrs.variant && !hasButtonStyle) {
                classes.add(`btn-${this.$attrs.variant ? this.$attrs.variant : "primary"}`)
                hasButtonStyle = true
            }

            if (!hasButtonStyle) {
                classes.add("btn-primary")
            }

            return Array.from(classes)
        }
    }
}
</script>

<style></style>
